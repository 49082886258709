exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-type-index-tsx-content-file-path-blog-triaging-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/index.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/triaging/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-index-tsx-content-file-path-blog-triaging-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-index-tsx-content-file-path-microblog-what-makes-a-great-software-engineer-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/index.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/microblog/what-makes-a-great-software-engineer/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-index-tsx-content-file-path-microblog-what-makes-a-great-software-engineer-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-debugging-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/debugging/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-debugging-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-paleolithic-emotions-and-god-like-technology-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/paleolithic-emotions-and-god-like-technology/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-paleolithic-emotions-and-god-like-technology-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-running-ethereum-node-part-1-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/running-ethereum-node-part-1/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-running-ethereum-node-part-1-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-running-ethereum-node-part-2-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/running-ethereum-node-part-2/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-running-ethereum-node-part-2-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-triaging-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/blog/triaging/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-blog-triaging-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-ba-computers-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/microblog/ba-computers/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-ba-computers-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-personality-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/microblog/personality/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-personality-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-principles-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/microblog/principles/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-principles-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-what-makes-a-great-software-engineer-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/dhvanipa/Documents/Personal/personal-website/microblog/what-makes-a-great-software-engineer/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-tsx-content-file-path-microblog-what-makes-a-great-software-engineer-index-mdx" */)
}

